import { MY_SITE_NAME } from '@utils/constant';
import { isAtSiteName } from '@utils/customsHook/useIsAtSiteName';
import { deepCopy } from '@utils/utility';

import GenderDatasourceModel from './model/GenderDatasource';

export default class SettingGlobalModel {
  constructor() {
    this.Country = '';

    this.LoginLink = '/';

    this.MaximumPhoneDigits = '';

    this.MinimumPhoneDigits = '';

    this.PhoneNumberFormat = '';

    this.PhoneNumberMinimumMust = '';

    this.PhoneNumberMust = '';

    this.ZipCodeNumberFormat = '';

    this.ZipCodeNumberMust = '';

    this.MyAccountPageLink = '/';

    this.UseFacebookLogin = false;

    this.UseGoogleLogin = false;

    this.NewsletterList = '';

    this.AreaCodes = [{ label: '', value: '' }];

    this.UseAreaCode = false;

    this.IsDoBRequired = false;

    this.IsGenderRequired = false;

    this.IsReCaptchaRequired = false;

    this.IsPhoneNumberRequired = false;

    this.GenderDatasource = [];

    this.CaptchaPublicKey = '';

    this.FormatName = 'First Name First';

    this.FullNameFormat = '';

    this.DefaultGender = '';

    this.DefaultSubscription = false;

    this.IsSubscriptionRequired = false;

    this.IsDisableGuestCheckout = false;

    this.RequiredAddressFields = [];

    this.DepositValidationCart = true;

    this.MemberPriceText = '';

    this.UsualPriceText = '';

    this.TooltipContent = '';

    this.TooltipIcon = '';

    this.ShowPasswordIcon = '';

    this.HidePasswordIcon = '';

    this.IncorrectPasswordIcon = '';

    this.CorrectPasswordIcon = '';

    this.PasswordHintText = '';

    this.CharacterLengthText = '';

    this.CharacterLengthMinimum = 0;

    this.NonAlphanumericCharactersText = '';

    this.NonAlphanumericRegex = '';

    this.UpperAndLowercaseText = '';

    this.UppercaseAndLowercaseRegex = '';

    this.AtLeastOneNumberText = '';

    this.NumberRegex = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'AreaCodes') && dataSource.AreaCodes.length) {
      this.AreaCodes.length = 0;

      dataSource.AreaCodes.map((item) => {
        this.AreaCodes.push({
          value: isAtSiteName(MY_SITE_NAME) ? item?.replace('+', '') : item,
          label: item
        });
      });
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PhoneNumberMust')) {
      this.PhoneNumberMust = dataSource?.PhoneNumberMust ? +dataSource.PhoneNumberMust : '';
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PhoneNumberMinimumMust')) {
      this.PhoneNumberMinimumMust = dataSource?.PhoneNumberMinimumMust ? +dataSource.PhoneNumberMinimumMust : '';
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Country')) {
      this.Country = dataSource?.Country;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'MaximumPhoneDigits')) {
      this.MaximumPhoneDigits = dataSource?.MaximumPhoneDigits;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'MinimumPhoneDigits')) {
      this.MinimumPhoneDigits = dataSource?.MinimumPhoneDigits;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LoginLink') && dataSource?.LoginLink) {
      this.LoginLink = dataSource.LoginLink;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PhoneNumberFormat')) {
      this.PhoneNumberFormat = dataSource?.PhoneNumberFormat;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ZipCodeNumberFormat')) {
      this.ZipCodeNumberFormat = dataSource?.ZipCodeNumberFormat;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ZipCodeNumberMust')) {
      this.ZipCodeNumberMust = dataSource?.ZipCodeNumberMust ? +dataSource.ZipCodeNumberMust : '';
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'MyAccountPageLink') && dataSource?.MyAccountPageLink) {
      this.MyAccountPageLink = dataSource.MyAccountPageLink;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'UseFacebookLogin')) {
      this.UseFacebookLogin = dataSource?.UseFacebookLogin;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'UseGoogleLogin')) {
      this.UseGoogleLogin = dataSource?.UseGoogleLogin;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'NewsletterList')) {
      this.NewsletterList = dataSource?.NewsletterList;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'UseAreaCode')) {
      this.UseAreaCode = dataSource.UseAreaCode;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsDoBRequired')) {
      this.IsDoBRequired = dataSource.IsDoBRequired ?? this.IsDoBRequired;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsGenderRequired')) {
      this.IsGenderRequired = dataSource.IsGenderRequired ?? this.IsGenderRequired;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsReCaptchaRequired')) {
      this.IsReCaptchaRequired = dataSource.IsReCaptchaRequired ?? this.IsReCaptchaRequired;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsPhoneNumberRequired')) {
      this.IsPhoneNumberRequired = dataSource.IsPhoneNumberRequired ?? this.IsPhoneNumberRequired;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'GenderDatasource')) {
      dataSource['GenderDatasource'].map((element) => {
        this['GenderDatasource'].push(new GenderDatasourceModel().getData(deepCopy(element) || {}));
      });
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CaptchaPublicKey')) {
      this.CaptchaPublicKey = dataSource.CaptchaPublicKey ?? this.CaptchaPublicKey;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'FormatName')) {
      this.FormatName = dataSource.FormatName ?? this.FormatName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'FullNameFormat')) {
      this.FullNameFormat = dataSource.FullNameFormat ?? this.FullNameFormat;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DefaultGender')) {
      this.DefaultGender = dataSource.DefaultGender.length ? dataSource.DefaultGender : this.DefaultGender;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DefaultSubscription')) {
      this.DefaultSubscription = dataSource.DefaultSubscription ?? this.DefaultSubscription;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsSubscriptionRequired')) {
      this.IsSubscriptionRequired = dataSource.IsSubscriptionRequired ?? this.IsSubscriptionRequired;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsDisableGuestCheckout')) {
      this.IsDisableGuestCheckout = dataSource.IsDisableGuestCheckout ?? this.IsDisableGuestCheckout;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'RequiredAddressFields')) {
      this.RequiredAddressFields = dataSource.RequiredAddressFields ?? this.RequiredAddressFields;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DepositValidationCart')) {
      this.DepositValidationCart = dataSource.DepositValidationCart ?? this.DepositValidationCart;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'MemberPriceText')) {
      this.MemberPriceText = dataSource.MemberPriceText ?? this.MemberPriceText;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'UsualPriceText')) {
      this.UsualPriceText = dataSource.UsualPriceText ?? this.UsualPriceText;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TooltipContent')) {
      this.TooltipContent = dataSource.TooltipContent ?? this.TooltipContent;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TooltipIcon')) {
      this.TooltipIcon = dataSource.TooltipIcon ?? this.TooltipIcon;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ShowPasswordIcon')) {
      this.ShowPasswordIcon = dataSource.ShowPasswordIcon ?? this.ShowPasswordIcon;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'HidePasswordIcon')) {
      this.HidePasswordIcon = dataSource.HidePasswordIcon ?? this.HidePasswordIcon;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IncorrectPasswordIcon')) {
      this.IncorrectPasswordIcon = dataSource.IncorrectPasswordIcon ?? this.IncorrectPasswordIcon;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CorrectPasswordIcon')) {
      this.CorrectPasswordIcon = dataSource.CorrectPasswordIcon ?? this.CorrectPasswordIcon;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PasswordHintText')) {
      this.PasswordHintText = dataSource.PasswordHintText ?? this.PasswordHintText;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CharacterLengthText')) {
      this.CharacterLengthText = dataSource.CharacterLengthText ?? this.CharacterLengthText;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CharacterLengthMinimum')) {
      this.CharacterLengthMinimum = dataSource.CharacterLengthMinimum ?? this.CharacterLengthMinimum;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'NonAlphanumericCharactersText')) {
      this.NonAlphanumericCharactersText =
        dataSource.NonAlphanumericCharactersText ?? this.NonAlphanumericCharactersText;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'NonAlphanumericRegex')) {
      this.NonAlphanumericRegex = dataSource.NonAlphanumericRegex ?? this.NonAlphanumericRegex;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'UpperAndLowercaseText')) {
      this.UpperAndLowercaseText = dataSource.UpperAndLowercaseText ?? this.UpperAndLowercaseText;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'UppercaseAndLowercaseRegex')) {
      this.UppercaseAndLowercaseRegex = dataSource.UppercaseAndLowercaseRegex ?? this.UppercaseAndLowercaseRegex;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'AtLeastOneNumberText')) {
      this.AtLeastOneNumberText = dataSource.AtLeastOneNumberText ?? this.AtLeastOneNumberText;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'NumberRegex')) {
      this.NumberRegex = dataSource.NumberRegex ?? this.NumberRegex;
    }

    return this;
  }
}
