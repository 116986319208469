import './CheckoutCreateAccount.scss';

import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
// import RegisterActiveMail from '@components/SingleSignOn/RegisterLogin/RegisterActiveMail';
import { register } from '@redux/actions/singleSignOn';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { MINUMUM_CHARACTERS_PASSWORD } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';

import PasswordInput from '../../SingleSignOn/PasswordInput';
import { useGeneratePasswordRegex } from '../../SingleSignOn/PasswordInput/hooks';

const CheckoutCreateAccount = ({ datafields, customerInfo, isLogin, errorMessage, isLoading, settingGlobal, isRegisteringNewAccount }) => {
  const [receiveSMS, setReceiveSMS] = useState(false);
  const [receiveEmail, setReceiveEmail] = useState(false);
  const [receiveDirectMailing, setReceiveDirectMailing] = useState(false);
  const [captchaErr, setCaptchaErr] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(settingGlobal?.DefaultSubscription);
  const recaptchaRef = useRef(null);
  const dispatch = useDispatch();
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const { regexSummary } = useGeneratePasswordRegex();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: '',
      agreeTerms: agreeTerms,
      captcha: '',
      receiveSms: null,
      receiveMail: null,
      receiveDirectMail: null
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(<Message messageCode='Msg_001.5' />)
        .matches(regexSummary, messageObj?.['Msg_001.5']),
      agreeTerms: settingGlobal?.IsSubscriptionRequired && Yup.bool().oneOf([true], <Message messageCode='Msg_015' />),
      captcha:
        settingGlobal?.IsReCaptchaRequired &&
        Yup.string()
          .required(<Message messageCode='Msg_001.6' />)
          .trim()
    }),
    onSubmit: (values) => {
      dispatch(
        register({
          FirstName: customerInfo.FirstName,
          LastName: customerInfo.LastName,
          Email: customerInfo.Email,
          PhoneNumber: customerInfo.PhoneNumber,
          Password: values.password,
          IsRegisterAfterOrder: true,
          OrderId: Global.getQueryParams(window.location.href, 'orderId') || '',
          ReCaptchaRequest: values.captcha,
          ReceiveSms: receiveSMS ? receiveSMS : null,
          ReceiveEmail: receiveEmail ? receiveEmail : null,
          ReceiveDirectMail: receiveDirectMailing ? receiveDirectMailing : null,
          Subscribed: agreeTerms
        })
      );
    }
    // validateOnMount: true
  });

  useDidUpdateEffect(() => {
    if (receiveSMS || receiveEmail || receiveDirectMailing) {
      setAgreeTerms(true);

      formik.setFieldValue('agreeTerms', true);
    }
  }, [receiveSMS, receiveEmail, receiveDirectMailing]);

  useDidUpdateEffect(() => {
    if (!agreeTerms) {
      setReceiveSMS(null);

      setReceiveEmail(null);

      setReceiveDirectMailing(null);
    }
  }, [agreeTerms]);

  return customerInfo?.Email && !isLogin ? (
    <div className='create-account thankyou-payment-comp'>
      {/* {isRegisterSuccess ? (
        <Fragment>
          <div className='container my-account-header__heading animated-slow fadeIn delay-100'>
            <h4 className='my-account-header__heading__title'>
              <Text field={datafields['Account Title']} />
            </h4>
          </div>
          <RegisterActiveMail dataSources={datafields['Register One Last Step']} emailRegister={customerInfo.Email} />
        </Fragment>
      ) : (
        <Fragment>
          <div className='container thankyou-payment-comp__wrap'>
            <div className='create-account__heading'>
              <h3 className='create-account__heading__title text-center'>
                <Text field={datafields['Checkout Register Account Title']}/>
              </h3>
              <RichText field={datafields['Checkout Register Account Message']} />
            </div>
            <form onSubmit={formik.handleSubmit} className='create-account__form'>
              {errorMessage && <span className='error-validate'>{errorMessage}</span>}
              <div className='form-group'>
                <input
                  type='password'
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  name='password'
                  className='form-control form-control-lg'
                  id='password'
                  placeholder={datafields['Checkout Register Account Password Label'].value}
                />
                {formik.errors.password && formik.touched.password && <span className='error-validate'><Message messageCode='Msg_001.5' /></span>}
              </div>
              <div className='form-group'>
                <div className='osim-checkbox'>
                  <input className='osim-checkbox-input' type='checkbox' id='osim-checkbox-1' />
                  <label className='osim-checkbox-label' htmlFor='osim-checkbox-1'><Text field={datafields['Register Subscription Label']} /></label>
                </div>
              </div>
              <div className='form-group'>
                <div className='osim-checkbox'>
                  <input className='osim-checkbox-input' type='checkbox' id='osim-checkbox-2' />
                  <label className='osim-checkbox-label' htmlFor='osim-checkbox-2'><Text field={datafields['PDPA Message']} /></label>
                </div>
              </div>
              <div className='create-account__form__btn text-center'>
                <button type='submit' className='create-account__form__btn__item btn btn-primary'>
                  <Text field={datafields['Checkout Register Account Register Button Text']} />
                </button>
              </div>
            </form>
          </div>
        </Fragment>
      )} */}
      <div className='container thankyou-payment-comp__wrap'>
        <div className='create-account__heading'>
          <h3 className='create-account__heading__title text-center'>
            <Text field={datafields['Checkout Register Account Title']} />
          </h3>
          <RichText field={datafields['Checkout Register Account Message']} />
        </div>
        <form onSubmit={formik.handleSubmit} className='create-account__form'>
          {errorMessage && <span className='error-validate'>{errorMessage}</span>}
          <div className='form-group'>
            <PasswordInput
              type='password'
              onChange={formik.handleChange}
              value={formik.values.password}
              name='password'
              className='form-control form-control-lg'
              id='password'
              placeholder={datafields['Checkout Register Account Password Label'].value}
              errorMessageEl={formik.errors.password && formik.touched.password && (
                <span className='error-validate'>
                  {formik.errors.password}
                </span>
              )}
            />
          </div>
          <div className='form-group'>
            <div className='osim-checkbox'>
              <input className='osim-checkbox-input' type='checkbox' id='osim-checkbox-1' checked={agreeTerms} name='agreeTerms' readOnly />
              <label
                className='osim-checkbox-label'
                htmlFor='osim-checkbox-1'
                onClick={() => {
                  setAgreeTerms(!agreeTerms);

                  formik.setFieldValue('agreeTerms', !agreeTerms);
                }}
              >
                <RichText field={datafields['Register Subscription Label']} />
              </label>
            </div>
            {formik.errors.agreeTerms && formik.touched.agreeTerms && (
              <span className='error-validate'>
                <Message messageCode='Msg_015' />
              </span>
            )}
          </div>
          {datafields['Enable Subscription'].value ? (
            <div className='form-group form-group--checkboxes form-group--checkbox-pd-left'>
              <Text tag='span' className='form-group--checkboxes__label' field={datafields['Subscription Description']} />
              <div className='form-group--checkboxes__list'>
                <div className='osim-checkbox'>
                  <input type='checkbox' id='receiveSms' name='receiveSms' className='osim-checkbox-input' checked={receiveSMS} readOnly />
                  <Text
                    field={datafields['SMS Subscription Label']}
                    tag='label'
                    className='osim-checkbox-label'
                    onClick={() => {
                      formik.setFieldValue('receiveSms', !receiveSMS);

                      setReceiveSMS(!receiveSMS);
                    }}
                  />
                </div>
                <div className='osim-checkbox'>
                  <input type='checkbox' id='receiveMail' name='receiveMail' className='osim-checkbox-input' checked={receiveEmail} readOnly />
                  <Text
                    field={datafields['Email Subscription Label']}
                    tag='label'
                    className='osim-checkbox-label'
                    onClick={() => {
                      formik.setFieldValue('receiveMail', !receiveEmail);

                      setReceiveEmail(!receiveEmail);
                    }}
                  />
                </div>
                <div className='osim-checkbox'>
                  <input
                    type='checkbox'
                    id='receiveDirectMail'
                    name='receiveDirectMail'
                    className='osim-checkbox-input'
                    checked={receiveDirectMailing}
                    readOnly
                  />
                  <Text
                    field={datafields['Direct Mailing Subscription Label']}
                    tag='label'
                    className='osim-checkbox-label'
                    onClick={() => {
                      formik.setFieldValue('receiveDirectMail', !receiveDirectMailing);

                      setReceiveDirectMailing(!receiveDirectMailing);
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {settingGlobal?.IsReCaptchaRequired && settingGlobal['CaptchaPublicKey'].length ? (
            <div className='form-group form-group--captcha'>
              <ReCAPTCHA
                sitekey={settingGlobal['CaptchaPublicKey']}
                onChange={(value) => formik.setFieldValue('captcha', value || '')}
                ref={recaptchaRef}
              />
              {formik.errors.captcha && formik.touched.captcha && captchaErr && (
                <span className='error-validate'>
                  <Message messageCode='Msg_001.6' />
                </span>
              )}
            </div>
          ) : (
            <></>
          )}
          {/* <div className='form-group'>
            <div className='osim-checkbox'>
              <input className='osim-checkbox-input' type='checkbox' id='osim-checkbox-2' />
              <label className='osim-checkbox-label' htmlFor='osim-checkbox-2'>
                <Text field={datafields['PDPA Message']} />
              </label>
            </div>
          </div> */}
          <div className='create-account__form__btn text-center'>
            <button
              disabled={isRegisteringNewAccount}
              type={settingGlobal?.IsSubscriptionRequired ? (agreeTerms ? 'submit' : 'button') : 'submit'}
              className={[
                settingGlobal?.IsSubscriptionRequired
                  ? agreeTerms
                    ? 'create-account__form__btn__item btn btn-primary'
                    : 'create-account__form__btn__item btn btn-primary btn-disable'
                  : 'create-account__form__btn__item btn btn-primary',
                isRegisteringNewAccount ? 'btn-disable' : ''
              ].join(' ')}
              onClick={() => {
                settingGlobal?.IsReCaptchaRequired && formik.values.captcha ? setCaptchaErr(false) : setCaptchaErr(true);

                settingGlobal?.IsReCaptchaRequired && formik.values.captcha && recaptchaRef.current.reset();

                settingGlobal?.IsReCaptchaRequired &&
                  formik.values.captcha &&
                  setTimeout(() => {
                    formik.setFieldValue('captcha', '');
                  }, 1000);
              }}
            >
              <Text field={datafields['Checkout Register Account Register Button Text']} />
            </button>
          </div>
        </form>
      </div>
      {isRegisteringNewAccount ? <LoadingMask /> : <></>}
    </div>
  ) : (
    ''
  );
};

CheckoutCreateAccount.propTypes = {
  datafields: PropTypes.object,
  customerInfo: PropTypes.object,
  isRegisterSuccess: PropTypes.bool,
  isLogin: PropTypes.bool,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  settingGlobal: PropTypes.object,
  isRegisteringNewAccount: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isRegisterSuccess: state.singleSignOnReducer.registerReducer.isRegister,
  isLogin: state.singleSignOnReducer.userInfoReducer?.isLogin,
  isLoading: state.singleSignOnReducer.registerReducer.isLoading,
  errorMessage: state.singleSignOnReducer.registerReducer.errorMessage,
  customerInfo: state.checkoutProcesstReducer.paymentDetailReducer.paymentDetail?.CustomerInfo,
  settingGlobal: state.settingGlobalReducer.settingGlobal,
  isRegisteringNewAccount: state.singleSignOnReducer.registerReducer.isRegisteringNewAccount,
});

export default connect(mapStateToProps)(CheckoutCreateAccount);
