import './CustomerInterestPage.scss';

import { any } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProductInterestItem from '@components/ClientPortal/GenericComponents/ProductInterestItem';
import { getCPCustomer } from '@redux/actions/clientTellingPortalCustomer';
import { useFormatDate } from '@utils/customsHook/useFormatDate';

import SitecoreContextFactoryService from '../../../services/sitecoreContextFactoryService';
import { HK_SITE_NAME, SITE_CHINESE_LANGUAGE_KEY, TW_SITE_NAME } from '../../../utils/constant';
import { useIsAtSiteName } from '../../../utils/customsHook/useIsAtSiteName';
import LoadingMask from '../../Share/LoadingMask';
import { getUserFullNameWithoutFormat } from '../../SlicingPage/MyAccount/utils';
import CPPageHeader from '../CPPageHeader';
import CustomerInterestPageModel from './Model/CustomerInterestPageModel';

const CustomerInterestPage = ({ fields }) => {
  const dispatch = useDispatch();
  const [layoutData, setLayoutData] = useState(null);
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);
  const clientProductInterest = clientTellingPortalCustomer?.currentCustomerDetail?.CPCustomerInterest?.Products;
  const formatDateUtil = useFormatDate();
  const isTWSite = useIsAtSiteName(TW_SITE_NAME);
  const isHKSite = useIsAtSiteName(HK_SITE_NAME);
  const currentLanguage = SitecoreContextFactoryService.getValueContextItem('language');

  useEffect(() => {
    const model = new CustomerInterestPageModel();
    let url = new URL(window.location.href);

    setLayoutData(model.getData({ ...fields } || {}));

    dispatch(
      getCPCustomer({
        id: url.searchParams.get('contactId'),
        fromURL: true
      })
    );
  }, []);

  const pageHeaderTitle = useMemo(
    () => {
      const personalInformation = clientTellingPortalCustomer?.currentCustomerDetail?.PersonalInformation;
      const firstName = personalInformation?.FirstName;
      const lastName = personalInformation?.LastName;
      const fullName = getUserFullNameWithoutFormat({ firstName, lastName });

      const isRemoveSLetter = isTWSite || (
        isHKSite && currentLanguage === SITE_CHINESE_LANGUAGE_KEY
      );

      return `${fullName}${isRemoveSLetter ? '' : '\'s'} ${layoutData?.['Section Title']?.value}`;
    },
    [
      clientTellingPortalCustomer?.currentCustomerDetail?.PersonalInformation,
      layoutData,
      isHKSite,
      isTWSite,
      currentLanguage
    ]
  );

  return layoutData ? (
    clientTellingPortalCustomer.isLoading ? (
      <LoadingMask />
    ) : (
      <>
        <CPPageHeader
          title={{
            value: pageHeaderTitle
          }}
          description={{
            value: `${layoutData['Description'].value.replace('{products-total}', clientProductInterest?.length)}`
          }}
        />
        <div className='cip-review'>
          <div className='container tablet-container'>
            <div className='cip-review__wrap'>
              {clientProductInterest?.length > 0 ? (
                clientProductInterest.map((val, index) => (
                  <div key={index} className='cip-review__product-item'>
                    <ProductInterestItem
                      layoutData={layoutData}
                      productImg={val.Image}
                      productName={val.ProductName}
                      // productSubName={val.productSubName}
                      productBadge={val.Questions}
                      productPurchase={formatDateUtil(val.DateCreated)}
                      isPurchaseOnSpot={val.IsPos}
                      questionData={val.Questions}
                    />
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </>
    )
  ) : (
    <></>
  );
};

CustomerInterestPage.propTypes = {
  fields: any
};

export default CustomerInterestPage;
