import './ProductManual.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { headerOffsetHeight } from '@components/Share/Header/headerHeightService';
import LoadingMask from '@components/Share/LoadingMask';
import { MEDIA_TRACKING_GOAL_ID, mediaStartTrackingGoal } from '@redux/actions/trackingGoal/mediaTracking/index';
import { mediaTrackingGoalSelector } from '@redux/reducers/mediaTrackingGoal/selector';
import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';
import {
  MEDIA_EXTENSION_IS_ALLOWED_TRIGGER_GOAL,
  MEDIA_MIME_TYPE_IS_ALLOWED_TRIGGER_GOAL,
  MEDIA_NAME_IS_ALLOWED_TRIGGER_GOAL
} from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import ProductManualModel from '../Models/ProductManualModel';

const ProductManual = ({ fields }) => {
  const dispatch = useDispatch();
  const [productManualFields, setProductManualFields] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(null);

  const {
    success,
    isLoading
  } = useSelector(mediaTrackingGoalSelector);
  const productManualFileValue = useMemo(() => productManualFields?.File?.value, [productManualFields]);

  useEffect(() => {
    const productManualModel = new ProductManualModel();

    setProductManualFields(productManualModel.getData(deepCopy(fields || {})));
  }, []);

  useEffect(() => {
    const headerSubscription = headerOffsetHeight.subscribe((item) => item && setHeaderHeight(item));

    return () => {
      headerSubscription.unsubscribe();
    };
  }, [headerOffsetHeight]);

  useEffect(() => {
    Global.scrollWithHash(headerHeight);
  }, [productManualFields, headerHeight]);

  useDidUpdateEffect(() => {
    if (typeof window !== 'undefined' && !isLoading && success) {
      window.location.href = productManualFileValue?.src || '';
    }
  }, [isLoading, success, productManualFileValue]);

  const isAllowTriggerGoal = useMemo(() => {
    if (productManualFileValue && productManualFileValue?.src) {
      return MEDIA_NAME_IS_ALLOWED_TRIGGER_GOAL.test(productManualFileValue?.src)
        || MEDIA_EXTENSION_IS_ALLOWED_TRIGGER_GOAL.test(
          productManualFileValue?.extension)
        || MEDIA_MIME_TYPE_IS_ALLOWED_TRIGGER_GOAL.test(productManualFileValue?.mimeType);
    }

    return false;
  }, [productManualFileValue]);

  const handleTrackingGoal = (event) => {
    if (isAllowTriggerGoal) {
      event.preventDefault();

      dispatch(mediaStartTrackingGoal({
        'GoalId': MEDIA_TRACKING_GOAL_ID,
        'UseTrackerAPI': false
      }));
    }
  };

  return productManualFields && (
    <>
      <div id='productmanual' className='product-manual'>
        <div className='container'>
          <Text tag='h2' className='text-center section-title' field={productManualFields['Title']} />
          <div className='product-manual__image text-center'>
            <Image media={productManualFields['Icon']} />
          </div>
          <Link
            field={{ href: productManualFileValue?.src || '' }}
            onClick={handleTrackingGoal}
            className='product-manual__link link-text text-center'
          >
            <Text field={productManualFields['Button Text']} />
          </Link>
        </div>
      </div>
      {isLoading ? <LoadingMask /> : <></>}
    </>
  );
};

ProductManual.propTypes = {
  fields: PropTypes.object
};

export default ProductManual;
