import './PaymentMethod.scss';

import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import Loading from '@components/Share/Loading';
import { getBalanceBillingData, getBillingData, submitOrder, submitOrderPayBalance } from '@redux/actions/checkoutProcess';
import { getSubscribe } from '@redux/actions/myAccount';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { MY_SITE_NAME } from '@utils/constant';
import { useDepositValidationCart } from '@utils/customsHook/useDepositValidationCart';
import { useIsAtSiteName } from '@utils/customsHook/useIsAtSiteName';
import { useQuery } from '@utils/customsHook/useQuery';
import global from '@utils/global';
import Global from '@utils/global';

import { VIEW_OFFER_DETAIL_CART_ID_KEY } from '../../PersonalizedOffer/hooks/useGenerateViewOfferDetailLink';
import LoadingMask from '../../Share/LoadingMask';
import CartDetailModal from '../CartDetails/CartDetailModal/index';
import { useCheckSelectedPayment, useGetCurrentPaymentOptions, useSetPaymentDefault, useUpdatePaymentOption } from './useUpdatePaymentOption';

const PaymentMethod = ({
  layoutData,
  paymentData,
  redirectUrl,
  isLogin,
  guestInfo,
  userInfo,
  isSubmittingOrder,
  paymentError,
  newsletterList,
  cartValue,
  isGettingCartData
}) => {
  const query = useQuery();
  const [aggreed, setAggreed] = useState(false);
  const [secondAggreed, setSecondAggreed] = useState(false);
  const [receiveOnlineOffer, setReceiveOnlineOffer] = useState(false);
  const [specialRequest, setSpecialRequest] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [selectedPaymentMethodCode, setSelectedPaymentMethodCode] = useState('');
  const [selectedPaymentPolicyId, setSelectedPaymentPolicyId] = useState('');
  const [selectedBankCode, setSelectedBankCode] = useState('');
  const [selectedInstallment, setselectedInstallment] = useState(null);
  const dispatch = useDispatch();
  const currentCart = useSelector((state) => state.productReducer.cartDetailReducer.currentCart);

  const isDeposit = useSelector(
    (state) =>
      state?.myOrderReducer?.balanceByIdReducer?.currentCartCustom?.IsDeposit || Boolean(state?.productReducer?.cartDetailReducer?.currentCartCustom?.DepositListProduct?.length)
  );
  const IsAuthenticated = SitecoreContextFactoryService.getValueContextItem('IsAuthenticated');
  const isGA4 = Global.getIsGA4();
  const updatePaymentOptionService = useUpdatePaymentOption();
  const [isUpdatePaymentOption, setIsUpdatePaymentOption] = useState(false);
  const templateId = SitecoreContextFactoryService.getValueContextItem('route')?.templateId;
  const checkSelectedPaymentService = useCheckSelectedPayment();
  const setDefaultPaymentOptionService = useSetPaymentDefault();
  const displayName = SitecoreContextFactoryService.getValueContextItem('route')?.displayName;
  const currentCartCustom = useSelector((state) => state?.productReducer?.cartDetailReducer?.currentCartCustom);
  const [isShow, setShow] = useState(false);
  const currentPaymentOptions = useGetCurrentPaymentOptions();
  const [isSetPaymentDefault, setIsSetPaymentDefault] = useState(false);
  const isAtMYSite = useIsAtSiteName(MY_SITE_NAME);
  const maxLengthSpecialRequest = layoutData?.['Special Request Maximum']?.value;
  const isValidateDepositCart = useDepositValidationCart();

  const osimSelectPaymentOptions = useMemo(() => {
    return currentPaymentOptions?.osimSelectedPaymentOption;
  }, [currentPaymentOptions]);

  const onCloseHandler = () => {
    setShow(false);
  };

  const backToFulfilmentLink = useMemo(() => {
    let linkField = layoutData?.['Back To Fulfilment Link'];
    if (query.get(VIEW_OFFER_DETAIL_CART_ID_KEY)) linkField = layoutData?.['Back To Fulfilment Offer Link'];

    return {
      ...linkField,
      value: {
        ...linkField?.value,
        href: `${linkField?.value?.href}?${query.toString()}`
      }
    };
  }, [ query, layoutData]);

  useEffect(() => {
    if (displayName !== 'Pay Balance') {
      dispatch(getBillingData());
    }
  }, []);

  useEffect(() => {
    if (redirectUrl) {
      if (redirectUrl.HttpMethod !== 'Post' && redirectUrl.Success) {
        // INF: Google analytics
        if (window['google_tag_manager'] && !isGA4) {
          window.dataLayer = window.dataLayer || [];
          let products = [];

          if (cartValue.Lines.length) {
            cartValue.Lines.forEach((line) => {
              products.push({
                name: line?.DisplayName,
                id: line?.ProductId,
                price: line?.LineFinalTotalAmount.toString(),
                category: line?.CategoryName,
                quantity: parseInt(line?.Quantity)
              });
            });
          }

          let obj = {
            ecommerce: {
              checkout: {
                actionField: { step: 3 },
                products: products
              }
            },
            event: 'checkout',
            eventCallback: function () {
              document.location = redirectUrl.PaymentRedirectUrl;
            },
            eventTimeout: 2000
          };

          window.dataLayer.push(obj);
          // new GoogleAnalytic().ggGateWay('event', 'checkout', obj);
        }

        window.location.href = redirectUrl.PaymentRedirectUrl;
      }
    }
  }, [redirectUrl]);

  useEffect(() => {
    if (paymentData && paymentData?.PayInFulls && cartValue) {
      if (cartValue?.OsimSelectedPaymentOption?.PaymentOptionId) {
        setSelectedPaymentMethod(cartValue?.OsimSelectedPaymentOption?.PaymentOptionId);

        setSelectedPaymentPolicyId(cartValue?.OsimSelectedPaymentOption?.PolicyId || '');

        setSelectedBankCode(cartValue?.OsimSelectedPaymentOption?.BankCode || '');
      } else {
        const payInFulls = paymentData?.PayInFulls[0];

        setSelectedPaymentMethod(payInFulls?.PaymentGatewayId || '');

        setSelectedPaymentMethodCode(payInFulls?.PaymentMethodCode || '');

        setSelectedPaymentPolicyId(payInFulls?.PolicyId || '');

        setSelectedBankCode(payInFulls?.BankCode || '');
      }

      GAAddPaymentInfo();
    }
  }, [paymentData, cartValue]);

  useEffect(() => {
    if (!isSetPaymentDefault) {
      if (selectedPaymentMethod || selectedInstallment || selectedBankCode || selectedPaymentMethodCode || selectedPaymentPolicyId) {
        (async () => {
          const params = {
            selectedPaymentMethod,
            selectedInstallment,
            selectedBankCode,
            selectedPaymentMethodCode,
            selectedPaymentPolicyId
          };

          await setDefaultPaymentOptionService(params);

          setIsSetPaymentDefault(true);
        })();
      }
    }
  }, [selectedPaymentMethod, selectedInstallment, selectedBankCode, selectedPaymentMethodCode, selectedPaymentPolicyId]);

  const GAAddPaymentInfo = () => {
    let params = {
      items: global.getItemList(cartValue.Lines),
      checkout_step: 3,
      checkout_option: 'payment info'
    };

    new GoogleAnalytic().ggGateWay('event', 'checkout_progress', params);
  };

  const onSelectMethod = (paymentId, methodCode = null, policyId = null) => {
    GAAddPaymentInfo();

    setSelectedPaymentMethod(paymentId);

    setSelectedPaymentMethodCode(methodCode);

    setSelectedPaymentPolicyId(policyId);

    setselectedInstallment(null);

    setSelectedBankCode(null);
  };

  const onSelectInstallment = (paymentId, period, bankCode = null, policyId = null) => {
    GAAddPaymentInfo();

    setSelectedPaymentMethod(paymentId);

    setselectedInstallment(period);

    setSelectedBankCode(bankCode);

    setSelectedPaymentPolicyId(policyId);

    setSelectedPaymentMethodCode('');
  };

  const onChangeSpecialRequest = (event) => {
    if (maxLengthSpecialRequest) {
      if (event.currentTarget.value.length <= maxLengthSpecialRequest) {
        setSpecialRequest(event.currentTarget.value);
      }
    } else {
      setSpecialRequest(event.currentTarget.value);
    }
  };

  const onPasteSpecialRequest = (event) => {
    event.preventDefault();
    const pastedText = (event.clipboardData || window.clipboardData).getData('text');
    const selectionStart = event.target.selectionStart;
    const selectionEnd = event.target.selectionEnd;
    const currentText = specialRequest;
    const newText = currentText.slice(0, selectionStart) + pastedText + currentText.slice(selectionEnd);
    const truncatedText = newText.slice(0, maxLengthSpecialRequest); 
    
    if (maxLengthSpecialRequest) {
      setSpecialRequest(truncatedText);
    } else {
      setSpecialRequest(newText);
    }
  };

  const checkTCCondition = () => {
    let TCCondition = layoutData['Terms And Conditions Label'].value !== '' ? aggreed : true;

    let SecondTCCondition = layoutData['Secondary Terms And Conditions Label'].value !== '' ? secondAggreed : true;

    return TCCondition && SecondTCCondition;
  };

  const handleAllowConfirmAndPay = useCallback(() => {
    const startDate = Date.parse(new Date(layoutData?.['Disable Confirm Button From'].value));
    const endDate = Date.parse(new Date(layoutData?.['Disable Confirm Button To'].value));
    const currentDate = Date.parse(new Date(Date.now()));

    return !(currentDate >= startDate && currentDate <= endDate);
  }, [layoutData?.['Disable Confirm Button From']?.value, layoutData?.['Disable Confirm Button To']?.value]);

  const confirmAndPay = () => {
    const isDepositFreeGift = currentCartCustom?.NormalListProduct?.every((item) => item.IsDepositFreeGift);

    const payload = {
      SpecialRequest: specialRequest,
      Payment: {
        PaymentMethodId: selectedPaymentMethod || currentPaymentOptions.paymentOptionIdFromApi || osimSelectPaymentOptions?.PaymentOptionId,
        PaymentMethodCode: selectedPaymentMethodCode,
        PolicyId: selectedPaymentPolicyId || currentPaymentOptions.policyIdFromApi || osimSelectPaymentOptions?.PolicyId,
        IsInstallmentPayment: selectedInstallment || selectedBankCode || osimSelectPaymentOptions?.Installment ? true : false,
        InstallmentPeriod: selectedInstallment || currentPaymentOptions.installmentPeriodFromApi || osimSelectPaymentOptions?.InstallmentPeriod || '',
        BankCode: selectedBankCode
      },
      PersonalInfo: {
        Email: isLogin ? userInfo.Email : guestInfo?.email || '',
        FirstName: isLogin ? userInfo.FirstName : guestInfo?.firstName || '',
        LastName: isLogin ? userInfo.LastName : guestInfo?.lastName || '',
        PhoneNumber: isLogin
          ? isAtMYSite
            ? userInfo.MYSitePhoneNumber
            : userInfo.PhoneNumber
          : `${guestInfo?.phoneNumberCode || ''}${guestInfo?.phoneNumber}` || '',
        Gender: isLogin ? userInfo.Gender : guestInfo?.gender || '',
        DoB: isLogin ? userInfo.DoB : guestInfo?.dob || ''
      }
    };

    //Old Logic GA
    // INF: Google analytics
    // const params = {
    //   coupon: '',
    //   currency: '',
    //   items: global.getItemList(cartValue?.Lines || []),
    //   payment_type: getPaymentMethodName(),
    //   value: cartValue.TotalAmount
    // };

    // new GoogleAnalytic().ggGateWay('event', 'add_payment_info', params);

    //New Logic ON/OFF GA, GA4
    if (isGA4) {
      const cartLines = cartValue?.Lines.map((line) => ({
        ...line,
        itemListName:
          global.getGA4Params()?.[line.VariantId] || SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Page Title']?.value
      }));

      const params = {
        ecommerce: {
          currency: cartValue?.Currency || '',
          value: cartValue.TotalAmount,
          coupon: cartValue?.PromoCodes?.toString() || '',
          user_type: IsAuthenticated ? 'registered' : 'guest',
          order_type: cartValue?.Lines?.[0]?.DeliveryOptions?.[0]?.Name || '',
          payment_type: getPaymentMethodName(),
          items: global.getItems(cartLines || [], cartValue?.PromoCodes?.toString() || '', '', cartValue?.Currency || '')
        }
      };

      new GoogleAnalytic().gAnalytic4('event', 'add_payment_info', params);
    } else {
      const params = {
        coupon: '',
        currency: '',
        items: global.getItemList(cartValue?.Lines || []),
        payment_type: getPaymentMethodName(),
        value: cartValue.TotalAmount
      };

      new GoogleAnalytic().ggGateWay('event', 'add_payment_info', params);
    }

    if (currentCartCustom?.NormalListProduct?.length > 0 && currentCartCustom?.DepositListProduct?.length > 0 && isValidateDepositCart) {
      if (isDepositFreeGift) {
        setShow(false);
        if (templateId == '750a9e1e-043c-416b-8b31-5a83f04c3810') {
          const url = new URL(window.location.href);
          const orderId = url.searchParams.get('OrderId');

          dispatch(
            submitOrderPayBalance({
              ...payload,
              OrderId: orderId
            })
          );
        } else {
          dispatch(submitOrder(payload));
        }
      } else {
        setShow(true);
      }
    } else if (currentCartCustom?.DepositListProduct?.length > 1) {
      setShow(true);
    } else {
      setShow(false);
      if (templateId == '750a9e1e-043c-416b-8b31-5a83f04c3810') {
        const url = new URL(window.location.href);
        const orderId = url.searchParams.get('OrderId');

        dispatch(submitOrderPayBalance({ ...payload, OrderId: orderId }));
      } else {
        dispatch(submitOrder(payload));
      }
    }
  };

  const getPaymentMethodName = () => {
    let paymentMethod = '';

    paymentMethod = paymentData.PayInFulls?.find((item) => {
      return item.PaymentGatewayId === selectedPaymentMethod;
    })?.PaymentName;

    return paymentMethod;
  };

  const onReceiveOnlineOfferHandler = () => {
    setReceiveOnlineOffer(!receiveOnlineOffer);
    if (isLogin && !receiveOnlineOffer) {
      dispatch(
        getSubscribe({
          params: {
            isSubscribe: true,
            listId: newsletterList || ''
          }
        })
      );
    }
  };

  const handleUpdatePaymentOptionFreeGift = async (paymentOptionId, paymentName, policyId = '', isInstallment = false, installmentPeriod = '', bankCode = '') => {
    setIsUpdatePaymentOption(true);
    try {
      await updatePaymentOptionService(paymentOptionId, paymentName, policyId, isInstallment, installmentPeriod, bankCode);
    } catch (error) {
      console.log(error);
    } finally {
      setIsUpdatePaymentOption(false);
    }
  };

  const handleCheckSelectedPayment = useCallback(
    (item, installmentPeriod) => {
      const osimSelectedPaymentOption = cartValue?.OsimSelectedPaymentOption;
      const paymentOptionIdFromApi = osimSelectedPaymentOption?.PaymentOptionId || '';
      const paymentNameFromApi = osimSelectedPaymentOption?.PaymentName || '';
      const policyIdFromApi = osimSelectedPaymentOption?.PolicyId || '';
      const installmentPeriodFromApi = osimSelectedPaymentOption?.InstallmentPeriod || '';

      return (
        (item?.PaymentGatewayId || '') === paymentOptionIdFromApi &&
        (item?.PaymentName || '') === paymentNameFromApi &&
        (item?.PolicyId || '') === policyIdFromApi &&
        (installmentPeriod || '') === installmentPeriodFromApi
      );
    },
    [cartValue]
  );

  return paymentData ? (
    <>
      <div className='payment-method'>
        {!paymentData.isEmpty ? (
          <div className='payment-method__heading'>
            <div className='container'>
              <div className='payment-method__heading__icon'>
                <span className='package-icon package-icon--payment-method'></span>
              </div>
              <h3 className='payment-method__heading__title'>
                <Text field={layoutData['Payment Method Label']} />
              </h3>
            </div>
          </div>
        ) : null}
        {!paymentData.isEmpty ? (
          <div className='payment-method__content'>
            <div className='container'>
              <div className='payment-method__content__wrap'>
                <div className='row'>
                  {paymentData.PayInFulls && paymentData.PayInFulls.length > 0 ? (
                    <div className='col-lg-6'>
                      <div className='payment-method__content__group payment-method__content__group--pay-full'>
                        <h4 className='payment-method__content__group__label'>
                          <Text field={layoutData['Pay In Full Label']} />
                        </h4>
                        <ul className='payment-method__content__group__list'>
                          {paymentData.PayInFulls.map((item, ind) => {
                            return (
                              <li className='payment-method__item payment-method__item--have-card-power' key={ind}>
                                <div className='payment-method__item__radio osim-radio'>
                                  <input
                                    type='radio'
                                    checked={
                                      (selectedPaymentMethod === item.PaymentGatewayId &&
                                        !selectedInstallment &&
                                        !selectedBankCode &&
                                        selectedPaymentMethodCode === item.PaymentMethodCode &&
                                        selectedPaymentPolicyId === item.PolicyId) ||
                                      checkSelectedPaymentService(item, item?.InstallmentPeriod)
                                    }
                                    id={`payment-method-${ind}`}
                                    className='osim-radio-input'
                                    onChange={() => {
                                      // INFO: select method
                                      onSelectMethod(item.PaymentGatewayId, item.PaymentMethodCode, item.PolicyId);

                                      // INFO: update payment option
                                      handleUpdatePaymentOptionFreeGift(item.PaymentGatewayId, item.PaymentGatewayText, item.PolicyId);
                                    }}
                                  />
                                  <label htmlFor={`payment-method-${ind}`} className='osim-radio-label'></label>
                                </div>
                                <div
                                  className={`payment-method__item__image ${
                                    ind === paymentData.PayInFulls.length - 1 && item.PaymentGatewayLogo === '' ? 'none-border' : ''
                                  }`}
                                >
                                  {item.AcceptedPaymentMethods.map((paymentItem, ind) => {
                                    return <img src={paymentItem.Logo} key={ind} alt='card image' />;
                                  })}
                                </div>
                                {item?.PaymentGatewayText && item?.PaymentGatewayText?.length ? (
                                  <>
                                    <div className='payment-method__item__card-text'>
                                      <span>
                                        <Text field={layoutData['Power By Label']} />
                                      </span>
                                      <span> {item?.PaymentGatewayText}</span>
                                    </div>
                                  </>
                                ) : (
                                  ''
                                )}
                                {item?.FullPaymentDescription && <p className='payment-method__item__title'>{item?.FullPaymentDescription}</p>}
                                {(!item?.PaymentGatewayText || !item?.PaymentGatewayText?.length) && item.PaymentGatewayLogo !== '' ? (
                                  <>
                                    <div className='payment-method__item__card-text'>
                                      <span>
                                        <Text field={layoutData['Power By Label']} />
                                      </span>
                                      <img src={item.PaymentGatewayLogo} alt='card' />
                                    </div>
                                  </>
                                ) : (
                                  ''
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  ) : null}
                  {paymentData.PayInInstallments && paymentData.PayInInstallments.length && !isDeposit ? (
                    <div className='col-lg-6'>
                      <div className='payment-method__content__group payment-method__content__group--pay-installments'>
                        <h4 className='payment-method__content__group__label'>
                          <Text field={layoutData['Pay In Instalments Label']} />
                        </h4>
                        <div className='bank-installment'>
                          {paymentData.PayInInstallments.map((item, ind) => {
                            return (
                              <div className='bank-installment__item' key={ind}>
                                {item.InstallmentLogo ? (
                                  <span className='payment-method__item__title payment-method__item__title--img'>
                                    <img src={item.InstallmentLogo} alt='installment logo' />
                                  </span>
                                ) : null}
                                <ul className='payment-method__content__group--installment-list'>
                                  {item.InstallmentBanks.map((bank, ind) => {
                                    return (
                                      <li className='payment-method__item' key={ind}>
                                        <div className='payment-method__item__radio osim-radio'>
                                          <input
                                            type='radio'
                                            checked={
                                              (selectedPaymentMethod === item.PaymentGatewayId &&
                                                selectedBankCode == bank.InstallmentBankCode &&
                                                selectedPaymentPolicyId == item.PolicyId) ||
                                              checkSelectedPaymentService(item, item?.InstallmentPeriod)
                                            }
                                            id={`${item.PaymentGatewayId}-${item.PolicyId}-bank-${ind}`}
                                            className='osim-radio-input'
                                            onChange={() => {
                                              // INFO: select installment
                                              onSelectInstallment(item.PaymentGatewayId, null, bank.InstallmentBankCode, item.PolicyId);

                                              // INFO: update payment option
                                              handleUpdatePaymentOptionFreeGift(item.PaymentGatewayId, item.PaymentGatewayText, item.PolicyId, true, '', bank.InstallmentBankCode);
                                            }}
                                            title={bank.InstallmentBank}
                                          />
                                          <label
                                            htmlFor={`${item.PaymentGatewayId}-${item.PolicyId}-bank-${ind}`}
                                            className='osim-radio-label'
                                          ></label>
                                        </div>
                                        <div className='payment-method__item__text'>
                                          <p
                                            className='payment-method__item__description'
                                            dangerouslySetInnerHTML={{ __html: bank.InstallmentBankDescription }}
                                          ></p>
                                        </div>
                                      </li>
                                    );
                                  })}
                                  {item.InstallmentPeriods.map((installmentItem, ind) => {
                                    return (
                                      <li className='payment-method__item' key={ind}>
                                        <div className='payment-method__item__radio osim-radio'>
                                          <input
                                            type='radio'
                                            onChange={() => {
                                              // INFO: select installment
                                              onSelectInstallment(item.PaymentGatewayId, installmentItem.InstallmentPeriod, item.BankCode || '', item.PolicyId);

                                              // INFO: update payment option
                                              handleUpdatePaymentOptionFreeGift(
                                                item.PaymentGatewayId,
                                                item.PaymentGatewayText,
                                                item.PolicyId,
                                                true,
                                                installmentItem.InstallmentPeriod,
                                                item.BankCode
                                              );
                                            }}
                                            checked={
                                              (selectedPaymentMethod === item.PaymentGatewayId &&
                                                selectedInstallment === installmentItem.InstallmentPeriod &&
                                                selectedPaymentPolicyId == item.PolicyId) ||
                                              checkSelectedPaymentService(item, installmentItem?.InstallmentPeriod)
                                            }
                                            id={`${item.PaymentGatewayId}-${item.PolicyId}-${ind}`}
                                            className='osim-radio-input'
                                          />
                                          <label htmlFor={`${item.PaymentGatewayId}-${item.PolicyId}-${ind}`} className='osim-radio-label'></label>
                                        </div>
                                        <div className='payment-method__item__text payment-method__item__text__icon'>
                                          <div className='payment-description'>
                                            <div className='payment-method__item__title'>
                                              {item.PaymentGatewayBigLogos?.length > 0 &&
                                                item.PaymentGatewayBigLogos.map((val, index) => (
                                                  <img className='payment-icon' key={index} src={val} alt='' />
                                                ))}
                                              <RichText field={{ value: installmentItem.InstallmentPeriodTitle }} />
                                            </div>
                                          </div>
                                          <p className='payment-method__item__description'>{installmentItem.InstallmentPeriodDescription}</p>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                                {item?.PaymentGatewayText && item?.PaymentGatewayText?.length ? (
                                  <>
                                    <div className='payment-method__item__card-text'>
                                      <span>
                                        <Text field={layoutData['Power By Label']} />
                                      </span>
                                      <span> {item?.PaymentGatewayText}</span>
                                    </div>
                                  </>
                                ) : (
                                  ''
                                )}
                                {(!item?.PaymentGatewayText || !item?.PaymentGatewayText?.length) && item.PaymentGatewayLogo !== '' ? (
                                  <>
                                    <div className='payment-method__item__card-text'>
                                      <span>
                                        <Text field={layoutData['Power By Label']} />
                                      </span>
                                      <img src={item.PaymentGatewayLogo} alt='card' />
                                    </div>
                                  </>
                                ) : (
                                  ''
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className='order-review-confirm container'>
          <div className='order-review-confirm__container'>
            <h3 className='order-review-confirm__heading payment-method__heading__title'>
              <Text field={layoutData['Special Request Label']} />
            </h3>
            <div className='order-review-confirm__body'>
              <p className='link-text link-text--gray-darker'>
                <Text field={layoutData['Special Request Description']} />
              </p>
              <textarea
                name=''
                value={specialRequest}
                onChange={onChangeSpecialRequest}
                onPaste={onPasteSpecialRequest}
                className='order-review-confirm__body__textarea'
                placeholder={layoutData['Special Request Placeholder Text'].value}
              ></textarea>
              {layoutData['Receive Offers Label'].value !== '' ? (
                <div className='checkout-checkbox'>
                  <input
                    type='checkbox'
                    defaultChecked={receiveOnlineOffer}
                    onClick={onReceiveOnlineOfferHandler}
                    name='special-request-notify'
                    id='special-request-notify'
                    className='checkout-checkbox__input'
                  />
                  <label htmlFor='special-request-notify' className='checkout-checkbox__label'>
                    <Text field={layoutData['Receive Offers Label']} />
                  </label>
                </div>
              ) : null}
              {layoutData['Terms And Conditions Label'].value !== '' ? (
                <div className='checkout-checkbox'>
                  <input
                    type='checkbox'
                    defaultChecked={aggreed}
                    onClick={() => setAggreed(!aggreed)}
                    name='special-request-term-conditions'
                    id='special-request-term-conditions'
                    className='checkout-checkbox__input'
                  />
                  <label htmlFor='special-request-term-conditions' className='checkout-checkbox__label'>
                    <Text field={layoutData['Agree Label']} />{' '}
                    <Link field={layoutData['Terms And Conditions Link']} className='term-link'>
                      <Text field={layoutData['Terms And Conditions Label']} />
                    </Link>
                  </label>
                </div>
              ) : null}
              {layoutData['Secondary Terms And Conditions Label'].value !== '' ? (
                <div className='checkout-checkbox'>
                  <input
                    type='checkbox'
                    defaultChecked={secondAggreed}
                    onClick={() => setSecondAggreed(!secondAggreed)}
                    name='second-term-conditions'
                    id='second-term-conditions'
                    className='checkout-checkbox__input'
                  />
                  <label htmlFor='second-term-conditions' className='checkout-checkbox__label'>
                    <RichText field={layoutData['Secondary Terms And Conditions Label']} />
                  </label>
                </div>
              ) : null}
            </div>

            <div className='fulfillment-options__button account-checking__form__button'>
              <div className='account-checking__form__button__submit text-center'>
                <button
                  type='submit'
                  className={`btn btn-primary ${
                    checkTCCondition() &&
                    (paymentData.isEmpty || selectedPaymentMethod) &&
                    !isUpdatePaymentOption &&
                    handleAllowConfirmAndPay() &&
                    !isSubmittingOrder
                      ? ''
                      : 'btn-disable'
                  }`}
                  onClick={() => confirmAndPay()}
                >
                  <Text field={layoutData['Confirm And Pay Label']} />
                </button>
                <CartDetailModal isShow={isShow} onCloseHandler={onCloseHandler} ondataSources={layoutData} />
              </div>
              <div className='account-checking__form__button__link text-center'>
                <a
                  target={backToFulfilmentLink.value.target}
                  href={backToFulfilmentLink.value.href}
                  className='btn-link btn-link--back'
                >
                  <Text field={layoutData['Back To Fulfilment Label']} />
                </a>
              </div>
              {isUpdatePaymentOption || isSubmittingOrder ? <Loading /> : ''}
              {paymentError && paymentError.length > 0 ? <p className='warning-message'>{paymentError[0]}</p> : ''}
            </div>
          </div>
        </div>
        <div className='hidden-form'>
          {redirectUrl && redirectUrl.HttpMethod === 'Post' ? (
            <form action={redirectUrl.PaymentRedirectUrl} method='Post' ref={(ref) => ref.submit()}>
              {Object.keys(redirectUrl.PostData).map((item, ind) => {
                return <input name={item} value={redirectUrl.PostData[item]} key={ind}></input>;
              })}
              <input id='submitOrder' type='submit' value='Submit'></input>
            </form>
          ) : null}
        </div>
      </div>
      {isUpdatePaymentOption || isGettingCartData ? <LoadingMask /> : <></>}
    </>
  ) : (
    <></>
  );
};

PaymentMethod.propTypes = {
  layoutData: PropTypes.any,
  paymentData: PropTypes.any,
  redirectUrl: PropTypes.any,
  isLogin: PropTypes.bool,
  userInfo: PropTypes.any,
  guestInfo: PropTypes.any,
  paymentError: PropTypes.any,
  cartValue: PropTypes.any,
  isSubmittingOrder: PropTypes.bool,
  newsletterList: PropTypes.string,
  isGettingCartData: PropTypes.bool
};

const mapStateToProps = (state) => {
  const currentDefaultCartData = state.productReducer.cartDetailReducer?.currentCart || null;
  const clientSOCartData = state.personalizeOfferReducer.getClientSOCartReducer.data?.OsimCart || null;

  return {
    paymentData: state.checkoutProcesstReducer.paymentReducer.paymentData || null,
    redirectUrl: state.checkoutProcesstReducer.paymentReducer.redirectUrl || null,
    isLogin: state.singleSignOnReducer.userInfoReducer.isLogin || false,
    userInfo: state.singleSignOnReducer.userInfoReducer.accountUser,
    guestInfo: state.checkoutProcesstReducer.dataCheckoutReducer.infoUser,
    isSubmittingOrder: state.checkoutProcesstReducer.paymentReducer?.isLoading,
    paymentError: state.checkoutProcesstReducer.paymentReducer?.submitOrderError,
    newsletterList: state.settingGlobalReducer.settingGlobal?.NewsletterList,
    isSubscribe: state.singleSignOnReducer.newsletterSubscribeReducer.isSubscribe,
    cartValue: clientSOCartData || currentDefaultCartData,
    isGettingCartData: state.productReducer.cartDetailReducer?.isLoading
  };
};

export default connect(mapStateToProps)(PaymentMethod);
