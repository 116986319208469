import '../AppointmentBookedComponent.scss';

import { Form, Formik } from 'formik';
import PropTypes, { any } from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
// import { propTypes } from 'react-bootstrap/esm/Image';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import DateOfBirth from '@components/SingleSignOn/DateOfBirth';
import { getContactStart } from '@redux/actions/campaignRegister/getContact';
import { checkCommerceUser } from '@redux/actions/checkoutProcess';
import { register } from '@redux/actions/singleSignOn';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { MINUMUM_CHARACTERS_PASSWORD } from '@utils/constant';
import Global from '@utils/global';

import PasswordInput from '../../../../SingleSignOn/PasswordInput';
import { useGeneratePasswordRegex } from '../../../../SingleSignOn/PasswordInput/hooks';

const RegisterAccountForm = (props) => {
  const { errorMessage, settingGlobal, dataSource, isRegisteringNewAccount } = props;
  const isLogin = useSelector((state) => state.singleSignOnReducer.userInfoReducer?.isLogin);
  const dispatch = useDispatch();
  const recaptchaRef = useRef(null);
  const [captchaErr, setCaptchaErr] = useState(false);
  // const [gender, setGender] = useState(null);
  // const [receiveOsim, setReceiveOsim] = useState(false);
  // const [receivePdpa, setReceivePdpa] = useState(false);
  const { contactData, isLoading: isGettingContactData } = useSelector((state) => state.campaignRegisterReducer.getContactReducer);
  const isEmailExist = useSelector((state) => state.checkoutProcesstReducer.accountCheckingReducer?.isCheckEmailExistBp);
  const { regexSummary } = useGeneratePasswordRegex();
  const messageObject = useSelector(state => state.getMessageReducer.objMessages);

  useEffect(() => {
    if (contactData?.EmailAddress) {
      dispatch(checkCommerceUser(contactData?.EmailAddress));
    }
  }, [contactData]);

  const schema = useMemo(() => {
    return Yup.object({
      password: Yup.string()
        .required(<Message messageCode='Msg_001.5' />)
        .matches(regexSummary, messageObject?.['Msg_001.5']),
      email: Yup.string()
        .required(<Message messageCode='Msg_001.3' />)
        .email(<Message messageCode='Msg_001.3' />),
      dob: Yup.string()
        .required(<Message messageCode='Msg_001.4' />)
        .trim(),
      gender: Yup.string()
        .required(<Message messageCode='Msg_019' />)
        .trim(),
      receiveOsim: Yup.boolean().oneOf([true], <Message messageCode='Msg_015' />),
      captcha: Yup.string()
        .required(<Message messageCode='Msg_001.6' />)
        .trim()
    });
  }, []);

  const initialValues = useMemo(() => {
    const defaultGender = settingGlobal?.GenderDatasource?.find((item) => {
      return item.Key === settingGlobal?.DefaultGender;
    });

    return {
      email: contactData?.EmailAddress || dataSource?.appointment?.EmailAddress || '',
      dob: contactData?.DateOfBirth || '',
      gender: contactData?.Gender || defaultGender?.Key || '',
      password: '',
      captcha: '',
      isEmailExist: '',
      receiveOsim: true,
      receiveSms: contactData?.AllowSMS || dataSource?.['smsMarketingChannelChecked']?.value,
      receiveMail: contactData?.AllowEmail || null,
      receiveDirectMail: contactData?.AllowDirectMail || null
    };
  }, [contactData, settingGlobal, dataSource]);

  const handleSubmit = (values) => {
    dispatch(
      register({
        FirstName: dataSource?.appointment?.FirstName,
        // LastName: dataSource?.appointment?.LastName,
        Email: values.email,
        Password: values.password,
        Gender: values.gender,
        DOB: values.dob,
        ReceiveOsim: values.receiveOsim,
        // ReceivePdpa: values.receivePdpa,
        ReceiveSms: values.receiveSms,
        ReceiveEmail: values.receiveEmail,
        ReceiveDirectMailing: values.receiveDirectMailing,
        ReCaptchaRequest: values.captcha
      })
    );
  };

  useEffect(() => {
    const params = {
      mobileNumber: props?.dataSource?.appointment?.MobileNumber,
      isBookExperience: true
      // emailAddress: props?.dataSource?.appointment?.EmailAddress
    };

    dispatch(getContactStart(params));
  }, []);

  return dataSource?.appointment?.IsBookExperience && !isLogin && !isEmailExist ? (
    !isGettingContactData ? (
      !contactData?.IsMobileNumberExistOsimAccount ? (
        <div className='appointment-booked-form'>
          <div className='container'>
            <div className='appointment-booked-form__content'>
              <Text field={dataSource?.youAreOneStepText} tag='h2' className='appointment-booked-form__title' />
              <RichText field={dataSource?.youAreOneStepDescription} tag='div' className='appointment-booked-form__info' />
              {errorMessage ? <span className='error-message'>{errorMessage}</span> : ''}
              <Formik initialValues={initialValues} validationSchema={schema} onSubmit={(values) => handleSubmit(values)} enableReinitialize={true}>
                {({ errors, touched, handleChange, values, setFieldValue }) => (
                  <Form id='appointment-booked-form__groups' className='appointment-booked-form__group'>
                    <div className='form-group'>
                      <input
                        onChange={handleChange}
                        value={values.email}
                        type='text'
                        className='form-control form-control-lg'
                        id='email'
                        name='email'
                        autoComplete='off'
                        placeholder={dataSource?.emailAddressText?.value}
                      />
                      <label htmlFor='email' className='form-group__label'>
                        <Text field={dataSource?.emailAddressText} />
                      </label>
                      {errors.email && touched.email && <span className='error-validate'>{errors.email}</span>}
                    </div>

                    <div className='form-group'>
                      <DateOfBirth setFieldValue={setFieldValue} dobLabel={dataSource?.dateOfBirthText} isRegister={true} defaultValue={values.dob} />
                      {errors.dob && touched.dob && <span className='error-validate'>{errors.dob}</span>}
                    </div>

                    <div className='form-group'>
                      <div className='form-group mb-0 form-group--gender'>
                        <label className='form-group__title'>
                          <Text field={dataSource?.genderText} />
                        </label>
                        {settingGlobal?.GenderDatasource.map((item, index) => {
                          return (
                            <div key={index} className='osim-radio'>
                              <input
                                type='radio'
                                name='gender'
                                value={item.Key}
                                className='osim-radio-input'
                                checked={values.gender.toLowerCase() === item.Key.toLowerCase()}
                                readOnly
                              />
                              <label
                                className='osim-radio-label'
                                onClick={() => {
                                  setFieldValue('gender', item.Key);
                                }}
                              />
                              <label
                                className='osim-radio-title'
                                onClick={() => {
                                  setFieldValue('gender', item.Key);
                                }}
                              >
                                {item.Value}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                      {errors.gender && touched.gender && <span className='error-validate'>{errors.gender}</span>}
                    </div>

                    <div className='form-group'>
                      <PasswordInput
                        onChange={handleChange}
                        value={values.password}
                        type='password'
                        className='form-control form-control-lg'
                        id='password'
                        name='password'
                        autoComplete='off'
                        placeholder={dataSource?.passwordText?.value}
                        errorMessageEl={errors.password && touched.password && (
                          <span className='error-validate'>
                            {errors.password}
                          </span>
                        )}
                      />
                      <label htmlFor='password' className='form-group__label'>
                        <Text field={dataSource?.passwordText} />
                      </label>
                    </div>

                    <div className='form-group'>
                      <div className='osim-checkbox form-group__checkbox'>
                        <input
                          type='checkbox'
                          id='receiveOsim'
                          name='receiveOsim'
                          className='osim-checkbox-input'
                          checked={values.receiveOsim}
                          readOnly
                        />
                        <label
                          htmlFor='receiveOsim'
                          className='osim-checkbox-label'
                          onClick={() => {
                            setFieldValue('receiveOsim', !values.receiveOsim);
                          }}
                        >
                          <RichText field={dataSource?.termsAndConditionsAgreementText} />
                        </label>
                        {errors.receiveOsim && touched.receiveOsim && <span className='error-validate'>{errors.receiveOsim}</span>}
                      </div>
                      {/* <div className='osim-checkbox'>
                      <input
                        type='checkbox'
                        id='receivePdpa'
                        name='receivePdpa'
                        className='osim-checkbox-input'
                        checked={values.receivePdpa}
                        readOnly
                      />
                      <label
                        htmlFor='receivePdpa'
                        className='osim-checkbox-label'
                        onClick={() => {
                          setFieldValue('receivePdpa', !values.receivePdpa);
                        }}
                      >
                        <Text field={dataSource?.pdpaCopyAndTermsText} />
                      </label>
                    </div> */}
                    </div>

                    <div className='form-group form-group--checkbox-pd-left'>
                      <Text tag='span' className='form-group--checkboxes__label' field={dataSource?.receivingMarketingInformationText} />
                      <div className='form-group--checkboxes__list'>
                        <div className='osim-checkbox'>
                          <input
                            type='checkbox'
                            id='receiveSms'
                            name='receiveSms'
                            className='osim-checkbox-input'
                            checked={values.receiveSms}
                            readOnly
                          />
                          <label
                            htmlFor='receiveSms'
                            className='osim-checkbox-label'
                            onClick={() => {
                              setFieldValue('receiveSms', !values.receiveSms);
                            }}
                          >
                            <Text field={dataSource?.smsMarketingChannelLabel} />
                          </label>
                        </div>
                        <div className='osim-checkbox'>
                          <input
                            type='checkbox'
                            id='receiveMail'
                            name='receiveMail'
                            className='osim-checkbox-input'
                            checked={values.receiveEmail}
                            readOnly
                          />
                          <label
                            htmlFor='receiveMail'
                            className='osim-checkbox-label'
                            onClick={() => {
                              setFieldValue('receiveMail', !values.receiveEmail);
                            }}
                          >
                            <Text field={dataSource?.emailMarketingChannelLabel} />
                          </label>
                        </div>
                        <div className='osim-checkbox'>
                          <input
                            type='checkbox'
                            id='receiveDirectMail'
                            name='receiveDirectMail'
                            className='osim-checkbox-input'
                            checked={values.receiveDirectMailing}
                            readOnly
                          />
                          <label
                            htmlFor='receiveDirectMail'
                            className='osim-checkbox-label'
                            onClick={() => {
                              setFieldValue('receiveDirectMail', !values.receiveDirectMailing);
                            }}
                          >
                            <Text field={dataSource?.directMailingMarketingChannelLabel} />
                          </label>
                        </div>
                      </div>
                    </div>

                    {settingGlobal?.IsReCaptchaRequired && settingGlobal['CaptchaPublicKey'].length ? (
                      <div className='form-group form-group--captcha'>
                        <ReCAPTCHA
                          sitekey={settingGlobal['CaptchaPublicKey']}
                          onChange={(value) => setFieldValue('captcha', value || '')}
                          ref={recaptchaRef}
                        />
                        {errors.captcha && touched.captcha && captchaErr && <span className='error-validate'>{errors.captcha}</span>}
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className='appointment-booked-form__submit'>
                      <button
                        className={['btn btn-primary', isRegisteringNewAccount ? 'btn-disable' : ''].join(' ')}
                        disabled={isRegisteringNewAccount}
                        onClick={() => {
                          settingGlobal?.IsReCaptchaRequired && values.captcha ? setCaptchaErr(false) : setCaptchaErr(true);

                          settingGlobal?.IsReCaptchaRequired && values.captcha && recaptchaRef.current.reset();

                          settingGlobal?.IsReCaptchaRequired &&
                          values.captcha &&
                          setTimeout(() => {
                            setFieldValue('captcha', '');
                          }, 1000);
                        }}
                        type='submit'
                      >
                        <Text field={dataSource?.registerAccountText} />
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              {isRegisteringNewAccount ? <LoadingMask /> : ''}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )
    ) : (
      <LoadingMask />
    )
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => ({
  isRegisterSuccess: state.singleSignOnReducer.userInfoReducer.accountUser?.IsRegisterNew,
  isLoading: state.singleSignOnReducer.registerReducer.isLoading,
  errorMessage: state.singleSignOnReducer.registerReducer.errorMessage,
  registerError: state.singleSignOnReducer.registerReducer.registerError,
  settingGlobal: state.settingGlobalReducer.settingGlobal,
  isRegisteringNewAccount: state.singleSignOnReducer.registerReducer.isRegisteringNewAccount,
});

RegisterAccountForm.propTypes = {
  dataSource: any,
  isRegisterSuccess: any,
  isLoading: any,
  errorMessage: any,
  registerError: any,
  settingGlobal: any,
  rendering: any,
  isRegisteringNewAccount: PropTypes.bool
};

export default connect(mapStateToProps)(RegisterAccountForm);
