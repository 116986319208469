import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import { changePasswordAccount } from '@redux/actions/myAccount';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { MINUMUM_CHARACTERS_PASSWORD } from '@utils/constant';
import { IS_USER_ENTER_OLD_PASSWORD_POLICY_SESSION_KEY } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';

import PasswordInput from '../../../../SingleSignOn/PasswordInput';
import { useGeneratePasswordRegex } from '../../../../SingleSignOn/PasswordInput/hooks';
import ChangePasswordSuccess from './ChangePasswordSuccess';
import ChangePasswordModel from './model/ChangePasswordModel';

const ChangePassword = ({fields, isChangePassword, errMessage, isLoading }) => {
  const [dataSources, setDataSources] = useState(null);
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const { regexSummary } = useGeneratePasswordRegex();

  const isUserEnteredOldPasswordPolicy = useMemo(() => {
    if (typeof window !== 'undefined') {
      return window.sessionStorage.getItem(IS_USER_ENTER_OLD_PASSWORD_POLICY_SESSION_KEY);
    }

    return false;
  }, []);

  useEffect(() => {
    setDataSources(new ChangePasswordModel().getData(fields || {}));
  }, []);
  
  useDidUpdateEffect(() => {
    const user = SitecoreContextFactoryService.getValueContextItem('user');
    if(settingGlobal && !user) {
      window.location.href = settingGlobal?.LoginLink;
    }

  }, [settingGlobal]);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassPassword: '',
      reTypePassPassword: ''
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string()
        .required(<Message messageCode='Msg_001.9' />),
      newPassPassword: Yup.string()
        .required(<Message messageCode='Msg_001.5' />)
        .matches(regexSummary, messageObj?.['Msg_001.5']),
      reTypePassPassword: Yup.string()
        .oneOf([Yup.ref('newPassPassword')], <Message messageCode='Msg_001.8' />)
        .required(<Message messageCode='Msg_001.5' />)
    }),
    onSubmit: (values) => {
      dispatch(changePasswordAccount({
        ConfirmPassword: values.newPassPassword,
        NewPassword: values.reTypePassPassword,
        OldPassword: values.currentPassword,
      }));
    },
    validateOnMount: true
  });

  useDidUpdateEffect(() => {
    if (typeof window !== 'undefined' && isUserEnteredOldPasswordPolicy && isChangePassword) {
      window.sessionStorage.removeItem(IS_USER_ENTER_OLD_PASSWORD_POLICY_SESSION_KEY);
    }
  }, [isChangePassword, isUserEnteredOldPasswordPolicy]);

  return dataSources ?  (
    <div className='contact-info'>
      <div className='container'>
        {!isChangePassword ?
          <div className='contact-info__wrap'>
            {isUserEnteredOldPasswordPolicy ? <Text
              tag='span'
              field={dataSources?.['Remind Change Password Message']}
              className='error-message text-center'
            /> : (
              <></>
            )}
            <form onSubmit={formik.handleSubmit} className='contact-info__form'>
              {errMessage ? <span className='error-message'><Message messageCode='Msg_001.9' /></span> : ''}
              <div className={Global.renderDynamicClass(formik.errors.currentPassword && formik.touched.currentPassword, 'form-group', 'input-error-validate')}>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.currentPassword}
                  type='password'
                  id='currentPassword'
                  placeholder={dataSources['Current Password Label'].value}
                  className={Global.renderDynamicClass(formik.values['currentPassword'], 'form-control form-control-lg', 'input-valid')}
                  name='currentPassword'
                />
                <label htmlFor='currentPassword' className='form-group__label'>
                  <Text field={dataSources['Current Password Label']} />
                </label>
                {formik.errors.currentPassword && formik.touched.currentPassword && (
                  <span className='error-validate'>{formik.errors.currentPassword}</span>
                )}
              </div>
              <div className={Global.renderDynamicClass(formik.errors.newPassPassword && formik.touched.newPassPassword, 'form-group', 'input-error-validate')}>
                <PasswordInput
                  type='password'
                  id='newPassPassword'
                  onChange={formik.handleChange}
                  value={formik.values.newPassPassword}
                  className={Global.renderDynamicClass(formik.values['newPassPassword'], 'form-control form-control-lg', 'input-valid')}
                  placeholder={dataSources['New Password Label'].value}
                  name='newPassPassword'
                  errorMessageEl={formik.errors.newPassPassword && formik.touched.newPassPassword && (
                    <span className='error-validate'>{<Message messageCode='Msg_001.5' />}</span>
                  )}
                />
                <label htmlFor='newPassPassword' className='form-group__label'>
                  <Text field={dataSources['New Password Label']} />
                </label>
              </div>
              <div className={Global.renderDynamicClass(formik.errors.reTypePassPassword && formik.touched.reTypePassPassword, 'form-group', 'input-error-validate')}>
                <PasswordInput
                  type='password'
                  id='reTypePassPassword'
                  onChange={formik.handleChange}
                  value={formik.values.reTypePassPassword}
                  className={Global.renderDynamicClass(formik.values['reTypePassPassword'], 'form-control form-control-lg', 'input-valid')}
                  placeholder={dataSources['Retype Password Label'].value}
                  name='reTypePassPassword'
                  errorMessageEl={formik.errors.reTypePassPassword && formik.touched.reTypePassPassword && (
                    <span className='error-validate'>{formik.errors.reTypePassPassword}</span>
                  )}
                />
                <label htmlFor='reTypePassPassword' className='form-group__label'>
                  <Text field={dataSources['Retype Password Label']} />
                </label>
              </div>
              <div className='contact-info__btn text-center'>
                <div className='contact-info__btn__item'>
                  <button type='submit' className='btn btn-outline-CTA1'>
                    <Text field={dataSources['Save Change Text']} />
                  </button>
                </div>
              </div>
            </form> 
          </div> : 
          <ChangePasswordSuccess dataSources={dataSources}/>
        }
      </div>
      {isLoading ? <LoadingMask/> : ''}
    </div>
  ) : '';
};

ChangePassword.propTypes = {
  emailUser: PropTypes.string,
  fields: PropTypes.object,
  setInputNewEmailEvt: PropTypes.func,
  errMessage: PropTypes.string,
  isChangePassword: PropTypes.bool,
  isLoading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isChangePassword: state.singleSignOnReducer.changePasswordReducer.isChangePassword,
  isLoading: state.singleSignOnReducer.changePasswordReducer.isLoading,
  errMessage: state.singleSignOnReducer.changePasswordReducer.errorMessage
});

export default connect(mapStateToProps)(ChangePassword);
