const LIST_MESSAGE = [
  'LBL-AddToWishlist',
  'LBL-BackOrder',
  'LBL-Free',
  'LBL-InStock',
  'LBL-Status',
  'LBL-NoSelection',
  'LBL-OutOfStock',
  'LBL-PreOrder',
  'LBL-RemoveToWishlist',
  'LBL-ShareLabel',
  'LBL-ShippingFee',
  'LBL-WarrantyFee',
  'Msg_001.1',
  'Msg_001.2',
  'Msg_002.3',
  'Msg_002.4',
  'Msg_002.5',
  'Msg_002.6',
  'Msg_002.7',
  'Msg_002.8',
  'Msg_002.9',
  'Msg_16',
  'Msg_014',
  'Msg_015',
  'Msg_001.3',
  'Msg_001.4',
  'Msg_001.5',
  'Msg_001.6',
  'Msg_001.7',
  'Msg_001.8',
  'Msg_001.9',
  'Msg_002.1',
  'Msg_002.2',
  'Msg_003',
  'Msg_004',
  'Msg_005',
  'Msg_006',
  'Msg_007',
  'Msg_008',
  'Msg_009',
  'Msg_010',
  'Msg_011',
  'Msg_012',
  'Msg_013',
  'Msg_019',
  'Msg_020',
  'Msg_021',
  'LBL-NotifyMeEmail',
  'LBL-NotifyMeFailMessage',
  'LBL-NotifyMe',
  'LBL-NotifyMeSuccessMessage',
  'PopUp-Description',
  'LBL-NotifyMeSubmit',
  'LBL-LearnMore',
  'Msg_018',
  'LBL-RentNowLabel',
  'LBL-Day',
  'LBL-Month',
  'LBL-Year',
  'LBL-Deposit',
  'LBL-Refundable',
  'LBL-RentalPrice',
  'Msg_017',
  'Msg_018_1',
  'Msg_018_2',
  'Msg_019_3',
  'Msg_019_4',
  'Msg_016',
  'Msg_021',
  'Msg_022',
  'Msg_025',
  'Msg_029',
  'Msg_031',
  'Msg_032_1',
  'Msg_032_2',
  'Msg_033_1',
  'Msg_033_2',
  'Msg_026_1',
  'Msg_026_2',
  'Msg_033',
  'Msg_022_3',
  'Msg_022_4',
  'Msg_022_5',
  'Msg_036',
  'Msg_037',
  'Msg_039',
  'Msg_042',
  'Msg_043',
  'Msg_044',
  'Msg_045',
  'Msg_001.10',
  'LBL_BundleDealsButtonUrl',
  'Annonymous User',
  'Identified Lead',
  'OSIM Customer',
  'Qualified Lead'
];

export default class MessageModel {
  constructor() {
    LIST_MESSAGE.map((messageItem) => {
      this[messageItem] = '';
    });
  }
  getData(dataSource) {
    LIST_MESSAGE.map((messageItem) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, messageItem)) {
        this[messageItem] = dataSource[messageItem];
      }
    });

    return this;
  }
}
